import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { LeadComponent } from './pages/lead/lead.component';
import { BannersComponent } from './pages/banners/banners.component';
import { LendersComponent } from './pages/lenders/lenders.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ViewagentComponent } from './pages/viewagent/viewagent.component';
import { SettingsComponent } from './pages/settings/settings.component';
import {  AuthGuardService as AuthGuard } from './services/auth-guard.service';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,

  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'viewagent/:id',
        component: ViewagentComponent,
        canActivate: [AuthGuard]
        },
       {
        path: 'leads',
        component: LeadsComponent,
        canActivate: [AuthGuard]

      },
        {
        path: 'agents',
        component: AgentsComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'lead/:id',
        component: LeadComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'banners',
        component: BannersComponent
      },
      {
        path: 'lenders',
        component: LendersComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: '',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
